:root {
    --primary-color: #3B82F6;
    --secondary-color: #10B981;
    --background-color: #F3F4F6;
    --text-color: #1F2937;
    --sidebar-width: 250px;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    color: var(--text-color);
  }
  
  .saviochat-container {
    display: flex;
    height: 100vh;
    background-color: var(--background-color);
  }
  
  /* Sidebar styles */
  .sidebar {
    width: var(--sidebar-width);
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-header {
    padding: 20px;
  }
  
  .sidebar-header h1 {
    font-size: 24px;
    margin: 0;
  }
  
  .sidebar-header p {
    font-size: 14px;
    color: #6B7280;
    margin: 5px 0 0;
  }
  
  .sidebar-nav {
    margin-top: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: var(--text-color);
    text-decoration: none;
    transition: background-color 0.2s;
  }
  
  .nav-item:hover, .nav-item.active {
    background-color: var(--background-color);
  }
  
  .nav-item svg {
    margin-right: 15px;
    width: 20px;
    height: 20px;
  }
  
  .add-conversation-button {
    margin: 20px;
    padding: 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .add-conversation-button svg {
    margin-right: 10px;
  }
  
  /* Chat area styles */
  .chat-area {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .model-selector {
    position: relative;
  }
  
  .model-button {
    display: flex;
    align-items: center;
    background-color: var(--background-color);
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .model-button:hover {
    background-color: #E5E7EB;
  }
  
  .model-button svg {
    margin-right: 5px;
  }
  
  .model-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .model-option {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .model-option:hover {
    background-color: var(--background-color);
  }
  
  .add-bot-button {
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .add-bot-button:hover {
    background-color: #0E9F6E;
  }
  
  .add-bot-button svg {
    margin-right: 5px;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #6B7280;
    transition: color 0.2s;
  }
  
  .close-button:hover {
    color: var(--text-color);
  }
  
  /* Messages container */
  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
  
  .message {
    display: flex;
    margin-bottom: 15px;
  }
  
  .message.user {
    justify-content: flex-end;
  }
  
  .message-content {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 15px;
    background-color: var(--primary-color);
    color: white;
  }
  
  .message.user .message-content {
    background-color: white;
    color: var(--text-color);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Input area */
  .input-area {
    display: flex;
    padding: 20px;
    background-color: white;
    border-top: 1px solid #E5E7EB;
  }
  
  .input-area input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #E5E7EB;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .input-area input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }
  
  .send-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .send-button:hover {
    background-color: #2563EB;
  }
  
  /* New styles for double chat and interconnect views */
  .double-chat-view, .interconnect-view {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .double-chat-view > div, .interconnect-view > div {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    border-right: 1px solid #E5E7EB;
  }
  
  .double-chat-view > div:last-child, .interconnect-view > div:last-child {
    border-right: none;
  }
  

  .message.partial .message-content {
    display: flex;
    align-items: center;
  }
  
  .typing-indicator {
    display: inline-block;
    width: 0.5em;
    height: 1em;
    margin-left: 0.2em;
    background-color: currentColor;
    animation: blink 0.7s infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }




  /* General styles */
.selectors-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.selector {
  position: relative;
}

.selector-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.selector-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.selector-option {
  display: block;
  width: 100%;
  text-align: left;
  padding: 12px 20px;
  border: none;
  background: none;
  transition: background-color 0.2s ease;
}

/* Role selector styles - Trustworthy and Professional */
.role-selector .selector-button {
  background-color: #f0f4f8;
  border: 2px solid #d0d9e1;
  color: #2c3e50;
}

.role-selector .selector-button:hover {
  background-color: #e1e8ed;
}

.role-selector .selector-menu {
  background-color: #ffffff;
  border: 2px solid #d0d9e1;
}

.role-selector .selector-option {
  color: #2c3e50;
}

.role-selector .selector-option:hover {
  background-color: #f0f4f8;
}

.role-selector .selector-search {
  padding: 12px;
  border-bottom: 1px solid #d0d9e1;
}

.role-selector .selector-search input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d9e1;
  border-radius: 4px;
  font-size: 14px;
}

/* Model selector styles - Luxurious and Powerful */
.model-selector .selector-button {
  background: linear-gradient(135deg, #2c3e50, #34495e);
  border: 2px solid #1a2530;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.model-selector .selector-button:hover {
  background: linear-gradient(135deg, #34495e, #2c3e50);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.model-selector .selector-menu {
  background: linear-gradient(135deg, #2c3e50, #34495e);
  border: 2px solid #1a2530;
}

.model-selector .selector-option {
  color: #ffffff;
}

.model-selector .selector-option:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Icons */
.selector-button svg {
  width: 20px;
  height: 20px;
}

.role-selector .selector-button svg {
  color: #3498db;
}

.model-selector .selector-button svg {
  color: #f1c40f;
}

/* Animations */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.model-selector .selector-button:active {
  animation: pulse 0.3s ease-in-out;
}


.profile-container {
  position: relative;
  margin-top: auto;
  padding: 10px;
}

.profile-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4285F4;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.profile-menu {
  position: absolute;
  right: 10px;
  bottom: 60px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  width: 200px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.signout-button {
  width: 100%;
  padding: 8px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.signout-button:hover {
  background-color: #d32f2f;
}