/* ChatContent.css */

.chat-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 120px);
  background-color: #F3F4F6;
}

.message {
  max-width: 70%;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.message:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.message.user {
  align-self: flex-end;
  background-color: #3B82F6;
  color: white;
}

.message.ai {
  align-self: flex-start;
  background-color: white;
  color: #1F2937;
}

.message-header {
  display: flex;
align-items: center;
margin-bottom: 5px;
}

.message-icon {
  margin-right: 8px;
}

.user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #10B981;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
}

.message-role {
  font-size: 0.8em;
  font-weight: bold;
  color: #050b17;
  margin-bottom: 5px;
}

.message-text {
  font-size: 1em;
  line-height: 1.5;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: #1F2937;
  margin-left: 2px;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.thinking-indicator {
  display: flex;
  align-items: center;
  color: #6B7280;
  font-style: italic;
}

.thinking-indicator .spin {
  margin-right: 8px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Scrollbar and responsive styles remain the same */
/* ... */

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  .chat-content {
    background-color: #1F2937;
  }

  .message.user {
    background-color: #3B82F6;
  }

  .message.ai {
    background-color: #374151;
    color: #F9FAFB;
  }

  .message-role {
    color: #9CA3AF;
  }

  .user-avatar {
    background-color: #065F46;
  }

  .cursor {
    background-color: #F9FAFB;
  }

  .thinking-indicator {
    color: #9CA3AF;
  }
}


.download-button {
  position: fixed;
  bottom: 120px; /* Increased from 20px to 80px to move it higher */
  right: 20px;
  z-index: 1000;
  background-color: rgba(0, 17, 115, 0.7);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.download-button:hover {
  background-color: rgba(0, 17, 115, 1);
  opacity: 1;
  transform: scale(1.1); /* Reduced from 1.2 to 1.1 for a subtler effect */
}

.download-button svg {
  color: white;
  width: 24px;
  height: 24px;
}


.code-button {
background-color: transparent;
color: #6e6e6e;
border: none;
cursor: pointer;
padding: 4px;
border-radius: 4px;
display: flex;
align-items: center;
transition: all 0.3s ease;
}

.code-button:hover {
background-color: rgba(0, 0, 0, 0.05);
color: #4a4a4a;
}

.code-button svg {
width: 16px;
height: 16px;
}

/* For dark mode */
@media (prefers-color-scheme: dark) {
.code-button {
  color: #b0b0b0;
}

.code-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
}