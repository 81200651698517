.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.select-container {
  display: flex;
  gap: 10px;
}

.model-select, .role-select {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.model-select {
  color: #333;
}

.role-select {
  color: #0056b3;
}

.add-savioz-btn {
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-savioz-btn:hover {
  background-color: #218838;
}