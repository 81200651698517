/* InputArea.css */

.input-area {
    display: flex;
    padding: 20px;
    background-color: #ffffff;
    border-top: 1px solid #e5e7eb;
    position: sticky;
    bottom: 0;
  }
  
  .input-area textarea {
    flex-grow: 1;
    min-height: 40px;
    max-height: 120px;
    padding: 10px 15px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    font-size: 16px;
    line-height: 1.5;
    resize: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .input-area textarea:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
  
  .input-area textarea::placeholder {
    color: #9ca3af;
  }
  
  .input-area textarea:disabled {
    background-color: #f3f4f6;
    cursor: not-allowed;
  }
  
  .input-area button {
    margin-left: 10px;
    padding: 0 20px;
    background-color: #3b82f6;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
  }
  
  .input-area button:hover {
    background-color: #2563eb;
  }
  
  .input-area button:active {
    transform: scale(0.98);
  }
  
  .input-area button:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
  
  /* Responsive Design */
  @media (max-width: 640px) {
    .input-area {
      padding: 15px;
    }
  
    .input-area textarea {
      font-size: 14px;
    }
  
    .input-area button {
      padding: 0 15px;
      font-size: 14px;
    }
  }
  
  /* Dark Mode */
  @media (prefers-color-scheme: dark) {
    .input-area {
      background-color: #1f2937;
      border-top-color: #374151;
    }
  
    .input-area textarea {
      background-color: #374151;
      border-color: #4b5563;
      color: #f9fafb;
    }
  
    .input-area textarea:focus {
      border-color: #60a5fa;
      box-shadow: 0 0 0 3px rgba(96, 165, 250, 0.1);
    }
  
    .input-area textarea::placeholder {
      color: #9ca3af;
    }
  
    .input-area textarea:disabled {
      background-color: #4b5563;
    }
  
    .input-area button {
      background-color: #3b82f6;
    }
  
    .input-area button:hover {
      background-color: #60a5fa;
    }
  
    .input-area button:disabled {
      background-color: #6b7280;
    }
  }