.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.products, .product, .cart {
  display: flex;
  flex-direction: column;
}

.product, .cart-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

button {
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: darkblue;
}