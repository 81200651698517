.waiting-list-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9fafb;
    font-family: 'Inter', sans-serif;
  }
  
  .waiting-list-card {
    background-color: #ffffff;
    padding: 2.5rem;
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    max-width: 32rem;
    width: 100%;
  }
  
  .waiting-list-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #111827;
  }
  
  .waiting-list-description {
    color: #6b7280;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5;
  }
  
  .waiting-list-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .waiting-list-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .waiting-list-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
  }
  
  .waiting-list-button {
    width: 100%;
    background-color: #3b82f6;
    color: #ffffff;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
  }
  
  .waiting-list-button:hover {
    background-color: #2563eb;
  }
  
  .waiting-list-button:active {
    transform: scale(0.98);
  }
  
  .waiting-list-message {
    margin-top: 1.5rem;
    text-align: center;
    color: #10b981;
    font-weight: 600;
  }